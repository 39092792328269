.overlay {
  background-color: #00000060;
  color: white;
  font-size: 14px;
  cursor: pointer;
  height: 42px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 16px;

  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.products-count {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
  }
}