@import "partials/_css_icons";
@import "partials/_mixins";
@import "partials/_variables";

.rent-my-style-quiz-modal {
    &:global(.ReactModal__Content--after-open) {
        height: 100%;
        padding: 0;
    }

    &:global(.modal-wrapper) {
        padding: 0;
    }
}

.overlay {
    :global(.modal-close) {
        margin-top: 20px;
        margin-right: 20px;
    }
}

.rent-my-style-quiz {
    height: 100vh;

    .header {
        height: 72px;
        border-bottom: solid #DDDDDD 1px;

        div {
            font-weight: 600;
            display: flex;
            justify-content: center;
            padding-top: 20px;
            font-size: 20px;
        }

        .back-btn {
            position: absolute;
            top: 14px;
            left: 50px;
            width: 70px;
            height: 40px;

            @include mobile-breakpoint {
                left: 20px;
            }

            &:after {
                @include line-arrow-left(6px);
                content: "";
                display: block;
                top: 16px;
                left: 2px;
                position: absolute;
              }
        }
    }

    .progress-bar-wrapper {
        height: 5px;
        margin: 40px 250px;
        background-color: $color-background-three;
        border-radius: 20px;

        @include mobile-breakpoint {
            margin: 25px 25px;
            height: 4px;
        }
    }

    .progress-bar {
        transition: width 0.4s ease-out 0.1s;
        height: 5px;
        background-color: black;
        border-radius: 20px;

        @include mobile-breakpoint {
            height: 4px;
        }
      }
}

.size-filters {
    margin: 10px auto;
    width: 440px;

    @include mobile-breakpoint {
        width: 100%;
    }

    :global(.filter-input-pairs) {
        justify-content: center;

        &:global(.filter-input-pairs--sizes) {
            :global(.input-wrapper) {
                margin: 8px;

                @include mobile-breakpoint {
                    margin: 4px;
                }
            }

            input[type="checkbox"] + label {
                height: 55px;
                width: 55px;
                line-height: 59px;
                font-size: 14px;

                @include mobile-breakpoint {
                    height: 48px;
                    width: 48px;
                    line-height: 49px;
                }
            }
        }
    }
}

.just-browsing-link {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
}

.header-copy {
    text-align: center;
    margin-bottom: 20px;

    @include mobile-breakpoint {
        margin: 0 25px 24px;

        h3 {
            font-size: 28px;
        }

        h4 {
            margin-bottom: 20px
        }
    }

    h3 {
        line-height: 100%;
        margin-bottom: 16px;
    }
}

.list-options {
    display: grid;
    grid-template-columns: 295px 295px;
    justify-content: center;
    column-gap: 16px;
    row-gap: 16px;

    :global(.btn-secondary) {
        border: 1px solid #E3E1DE;
        height: 60px;
        display: block;
        width: 98%;
        font-weight: 400;
    }

    @include mobile-breakpoint {
        grid-template-columns: 100%;

        :global(.btn-secondary) {
            height: 44px;
            margin: auto 15px;
            width: calc(100% - 30px);
        }
    }
}

.event-tiles {
    display: grid;
    grid-template-columns: 248px 248px 248px;
    justify-items: center;
    justify-content: center;
    column-gap: 16px;
    row-gap: 16px;
    
    @include mobile-breakpoint {
        grid-template-columns: 1fr 1fr;
        margin: 10px 16px;
    }

    a {
        width: 248px;
        height: 110px;
        border: 1px solid #E3E1DE;
        cursor: pointer;

        @include mobile-breakpoint {
            width: 100%;
        }
    }

    &__icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        :first-child {
            margin: 5px auto;
        }
    }
}

.cta-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: auto;

    @include mobile-breakpoint {
        background: #FFF;
        box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.14);
        margin-top: auto;
        position: sticky;
        bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    button,
    a {
        width: 315px;
        height: 44px;

        @include mobile-breakpoint {
            width: calc(100% - 48px);
        }
    }

    .secondary {
        text-decoration: underline;
    }

    .promo-message {
        text-align: center;
        padding-bottom: 20px;
        
        @include mobile-breakpoint {
            margin-bottom: 10px;
            font-size: 12px;
            padding: 0;
        }
    }
}

.quiz-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 185px);

    .zip-input {
        margin-bottom: 20px;

        &.error {
            color: red;

            input {
                border-color: red;
            }
        }

        h3 {
            text-align: center;
            margin-bottom: 10px;
        }

        input {
            margin: 0 auto;
            width: 300px;

            @include mobile-breakpoint {
                width: 90%;
            }
        }
    }

    .maternity-input {
        display: flex;
        justify-content: center;
        max-width: 300px;
        margin: 0 auto;

        input[type="checkbox"] + label {
            text-transform: none;
            font-size: 14px;
        }
    }

    :global(.datepicker) {
        width: 450px;
        margin: 0 auto;

        @include mobile-breakpoint {
            width: calc(100% - 48px);
            margin: 0 24px;
        }
    }

    :global(.datepicker-header-piece) {
        font-size: 14px;
        font-weight: 600;
    }

    h3 {
        text-align: center;
    }
    
    @include mobile-breakpoint {
        height: calc(100vh - 143px);
    }
}

.marketing-content {
    display: flex;
    height: calc(100vh - 73px);

    @include mobile-breakpoint {
        flex-direction: column;
    }

    .copy-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 2;
        text-align: center;
        margin-top: 25vh;

        @include mobile-breakpoint {
            margin-top: 0;

            .header-copy {
                margin: 24px;
            }
        }

        p {
            margin: 10px 0;
        }
    }

    img {
        flex-grow: 0;
    }

    .cta-container {
        margin: 0;

        @include mobile-breakpoint {
            box-shadow: none;
            position: static;
            padding-top: 0;
        }
    }
}
