@import "../../../../stylesheets/sass/partials/nce_common";


.topper,
.mobileTextBelow {
  .italic {
    font-style: italic;
  }

  .oblique {
    font-style: oblique;
  }

  :global(.atom-hed) {
    a {
      text-decoration: underline;
    }

    h1,
    h2 {
      color: var(--font-color);
    }

    h2 {
      @include body;
      @include font-sm;
    }
  }

  .eyebrowText {
    margin-bottom: 12px;
    text-transform: uppercase;
  }
}

.topper {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  .aiSearchFeedback {
    padding: 16px 0;
    font-size: 14px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  .subText {
    max-width: 470px;
  }

  @include tablet-and-desktop-breakpoint {
    padding: 0 72px;
    :global(.atom-hed) {
      h1 {
        font-size: 36px;
        white-space: nowrap;
      }
      h2 {
        white-space: pre-wrap;
      }
    }

    &.flexAlign {
      justify-content: space-between;
      align-items: center;
      text-align: left;

      .subText {
        margin-left: 48px;
        max-width: 55%;
      }
    }

    &.leftAlign,
    &.centerAlign,
    &.rightAlign {
      .subText {
        margin-top: 16px;
      }
    }

    &.centerAlign {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &.leftAlign {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
    }

    &.rightAlign {
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: right;
    }

    &.standard {
      height: 150px;
    }

    &.special {
      &.small {
        height: 200px;
      }

      &.large {
        height: 300px;
      }
    }
  }

  @include mobile-breakpoint {
    align-items: center;
    padding: 0 24px;
    :global(.atom-hed) {
      h1,
      h2 {
        white-space: pre-wrap;
      }
    }

    &.hasSubText {
      .hedText {
        margin-bottom: 8px;
      }
    }

    &.standard {
      flex-direction: column;
      justify-content: center;
      text-align: left;
      height: 100px;

      &.hasSubText {
        height: 160px;
      }

      .hedText,
      .subText {
        width: 100%;
      }
    }

    &.special {
      &.small,
      &.large {
        height: 160px;
      }

      &.small {
        flex-direction: column;
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.mobileTextBelow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100px;

  &.hasSubText {
    height: 160px;

    .hedText {
      margin-bottom: 16px;
    }
  }
}
