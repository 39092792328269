@import "../../../../../stylesheets/sass/partials/_responsive_mixins";

.full-bleed-fifty-fifty {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  .full-bleed-image {
    flex: 1 1 50%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    img,
    video {
      width: 100%;
    }

    img {
      aspect-ratio: var(--desktop-aspect-ratio);
      height: 100%;
      object-fit: cover;
      display: block;

      @include mobile-and-tablet-breakpoint {
        aspect-ratio: var(--mobile-aspect-ratio);
      }
    }

    a img {
      vertical-align: bottom;
    }
  }

  .images-container {
    display: flex;
    min-width: 100%;
    position: relative;

    @include mobile-and-tablet-breakpoint {
      aspect-ratio: var(--mobile-aspect-ratio);
      flex-direction: column;
    }
  }

  .image-text-content {
    text-align: center;
    padding: 15px;

    .bucket-eyebrow {
      @include eyebrow;
      margin-bottom: 8px;
      font-weight: bold;
    }

    .bucket-hedText {
      @include heading;
      @include heading-3-size;
      margin-bottom: 8px;
    }

    .bucket-dekText {
      @include body;
      @include body-2-size;
    }

    .bucket-cta-button {
      display: inline-block;
      margin-top: 10px;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
    }

    .homepage-tiles-cta {
      margin-bottom: 7vh;
    }
  }

  .homepage-tiles {
    margin: 3px;
  }

  .image-holder {
    position: relative;
  }

  .image-link {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
