@import "../../../../stylesheets/sass/partials/variables",
        "../../../../stylesheets/sass/partials/mixins";

.atom-spacer {
  // These are default value below. You can override these
  // values in the CMS. Take a look at atom-spacer.jsx

  &__container {
    height: 40px;
    margin: 0 auto;

    @include mobile-breakpoint {
      height: 30px;
    }
  }
}
