@import "_variables",
        "_responsive_mixins";

@mixin line-arrow($size, $color) {
    width: $size;
    height: $size;
    border-top: 1px solid $color;
    border-right: 1px solid $color;
    //Ensures that in the case where arrows are buttons,
    //there is no other outline.
    border-left: 0;
    border-bottom: 0;
    opacity: 1;

    &:hover {
        opacity: 0.6;
    }
}

@mixin line-arrow-right($size, $color: $color-primary-copy) {
    @include line-arrow($size, $color);
    @include rotate(45deg);
}

@mixin line-arrow-left($size, $color: $color-primary-copy) {
    @include line-arrow($size, $color);
    @include rotate(225deg);
}

@mixin line-arrow-down($size, $color: $color-primary-copy) {
    @include line-arrow($size, $color);
    @include rotate(135deg);
}

@mixin line-arrow-up($size, $color: $color-primary-copy) {
    @include line-arrow($size, $color);
    @include rotate(315deg);
}

@mixin carousel-dots {
    border-radius: 5px;
    border: 1px solid $color-background;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8)
    }
}

@mixin divider-line($width) {
    content: "";
    display: block;
    position: relative;
    margin: 0 auto;
    height: 1px;
    width: $width;
    background-color: $color-lines;
    box-sizing: border-box;
}

@mixin horizontal-divider-line($line-height, $height, $color: $color-primary-copy) {
    content: "";
    display: inline;
    position: absolute;
    width: 1px;
    margin-top: ($line-height - $height)*0.5;
    margin-left: -10px;
    height: $height;
    background-color: $color;
}
