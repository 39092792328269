@import "../../../../../stylesheets/sass/partials/nce_common";

.grid-query-carousels {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  :global(.atom-hed) {
    padding-bottom: 40px;
  }
}

.grid-query-carousel {
  margin-bottom: 50px;
  max-width: 100%;

  @include mobile-breakpoint {
    margin-bottom: 0;
  }

  &.slim {
    margin-bottom: 0;

    .secondary-copy,
    :global(.product-card-price__regular),
    :global(.product-card-price__adjusted),
    :global(.product-card-price__overwritten),
    :global(.product-card-price-annotation),
    :global(.grid-product-card-display-name),
    :global(.grid-product-card-available-sizes) {
      display: none;
    }

    .carousel-title {
      @include dek-one;
    }

    :global(.product-card-price__retail-quiet) {
      margin: 0;

      &:before {
        display: none;
      }
    }
  }

  .carousel-title {
    margin-left: 30px;
    text-align: left;
    float: left;
  }

  .carousel-title ~ .grid-link {
    float: right;
    margin-top: 4px; // This brings its baseline to the same as the title
    margin-right: 30px;
  }

  .grid-link {
    line-height: 27px; // This brings the view all link to the same line
    text-decoration: underline;
    text-transform: capitalize;
  }

  :global(.swipeable-carousel) {
    clear: both;
  }

  .view-all-carousel-item {
    @include border;
    padding-top: 75%;
    padding-bottom: 75%;
    text-align: center;
    transition: background-color 0.25s ease-in-out;

    .grid-link {
      padding-bottom: 3px;
    }

    &:hover {
      background-color: $color-background-one;
    }
  }

  &:global(__product-drawer) {
    margin-bottom: 50px;

    @include mobile-breakpoint {
      margin: 0 20px;
    }
  }
}

.grid-query-carousels.full-bleed-carousels {
  max-width: unset;

  .full-bleed-hed {
    padding: 0;

    h2 {
      @include heading;
      @include normal-weight;
      margin: 0 72px 36px;
      text-align: left;
      font-size: $font-size-heading-three;
      
      @include mobile-breakpoint {
        margin: 0 16px 42px;
        font-size: $font-size-cms-24;
      }
    }
  }

  .full-bleed-title-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 72px 24px;

    @include mobile-breakpoint {
      margin: 0 16px 24px;
    }

    .carousel-title {
      flex-grow: 1;
      margin: 0;
    }

    .carousel-title.main-title {
      @include heading;
      font-size: $font-size-heading-three;

      @include mobile-breakpoint {
        font-size: $font-size-cms-24;
      }
    }

    .grid-link {
      margin: 0;
      flex-grow: 0;
    }
  }

  :global(.swipeable-carousel__viewport--full-bleed-carousel) {
    margin: 0;

    :global(.swipeable-carousel__item--full-bleed-carousel) {
      flex-grow: 0;
      @include desktop-breakpoint {
        margin: 0;
        padding: 0 0.1%;
        flex-basis: 25%; // Desktop is 4 columns across
      }

      @include tablet-breakpoint {
        margin: 0;
        padding: 0 0.2%;
        flex-basis: 28.57%; // Tablet is 3.5 columns across
        &:first-of-type {
          margin-left: 16px;
        }
      }

      @include mobile-breakpoint {
        margin: 0;
        padding: 0 0.4%;
        flex-basis: 66.66%; // Mobile is 1.5 columns across (2nd column bleeding off screen)
        &:first-of-type {
          margin-left: 14px;
        }
      }

      :global(.grid-product-card-image) {
        img {
          aspect-ratio: 2 / 3; // to preserve the space before the images load
        }
      }
    }

    :global(.swipeable-carousel__item--full-bleed-carousel.swipeable-carousel__item--peek-carousel) {
      @include desktop-breakpoint {
        margin: 0;
        padding: 0 0.1%;
        flex-basis: 22.22%; // Desktop is 4.5 columns across
      }
    }
  }
}
