@import "../../../../stylesheets/sass/partials/responsive_mixins";

.social-topper-wrapper {
    padding: 20px 0;
}

.text-content {
    max-width: 500px;
    margin: 20px auto;
}

.event-option {
    margin: 10px auto;
}

.title {
    margin-bottom: 20px;
}

.selection-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.selection {
    margin: 0 15px;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-selected {
    padding-bottom: 5px;
}

.selected {
    padding-bottom: 2px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
}
